import MButton, { ButtonProps } from '@material-ui/core/Button'
import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { alpha, darken, lighten } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'

type StyleProps = {
  textColor?: Props['textColor']
  bgcolor?: Props['bgcolor']
  borderColor?: Props['borderColor']
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  link: {
    fontWeight: 300,
    fontSize: 12,
    color: palette.grey[700],
    '&:hover': {
      background: 'none',
    },
  },
  primaryLight: {
    color: palette.primary.main,
    border: `2px solid ${palette.primary.main}`,
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.1),
    },
  },
  light: {
    color: '#000',
    border: '2px solid #ECEDEF',
    '&:hover': {
      backgroundColor: '#ECEDEF',
      borderColor: '#ababab',
    },
  },
  green: {
    backgroundColor: '#20A964',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#20A964', 0.2),
    },
  },
  grey: {
    backgroundColor: '#52606D',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#52606D', 0.2),
    },
  },
  color: {
    color: (props: StyleProps) => (props.textColor ? props.textColor : 'inherit'),
  },
  bgcolor: {
    backgroundColor: (props: StyleProps) => (props.bgcolor ? props.bgcolor : 'inherit'),
    '&:hover': {
      backgroundColor: (props: StyleProps) => (props.bgcolor ? lighten(props.bgcolor, 0.2) : 'inherit'),
    },
  },
  borderColor: {
    borderColor: (props: StyleProps) => (props.borderColor ? props.borderColor : 'inherit'),
  },
}))

const variantsByKind: { [variantName: string]: ButtonProps['variant'] } = {
  primary: 'contained',
  primaryLight: 'outlined',
}

const colorsByKind: { [variantName: string]: ButtonProps['color'] } = {
  primary: 'primary',
  secondary: 'secondary',
}

export type Props = ButtonProps & {
  children: ReactNode
  kind?: 'primary' | 'primaryLight' | 'secondary' | 'light' | 'green' | 'grey'
  className?: string
  textColor?: string
  bgcolor?: string
  borderColor?: string
}

const Button = ({ kind, children, className, textColor, bgcolor, borderColor, size, ...props }: Props) => {
  const classes = useStyles({ textColor, bgcolor, borderColor })
  return (
    <MButton
      className={classnames(
        classes[kind],
        textColor && classes.color,
        bgcolor && classes.bgcolor,
        borderColor && classes.borderColor,
        className,
      )}
      variant={variantsByKind[kind]}
      color={colorsByKind[kind]}
      size={size || 'large'}
      {...props}
    >
      {children}
    </MButton>
  )
}

export default Button
